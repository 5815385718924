import React, { Component } from 'react';
import {StaticQuery, graphql} from 'gatsby';
import Blogcard from './blogcard'

const getPosts = graphql`

query {
    posts: allContentfulBlog(sort: { fields: publishDate, order: DESC }) {
      edges {
        node {
            publishDate(formatString: "MMMM Do, YYYY ")
          title
          
          slug
          
          
          image {
            fluid {
                ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`

console.log(getPosts);

class bloglist extends Component {
    render() {
        return (

            <StaticQuery  query={ getPosts} render={data =>{


                const posts = data.posts.edges;

                
return(<>
<div className="container-fluid " style={{backgroundColor:"#000000"}}>
  <div className="container py-5">
<div className="row">

{
    posts.map(({node:item}, i)=>{

return(


<>


<Blogcard key={i}  post = {item}/>

</>

)

    })
}
  </div>
  </div>
</div>
</>)

            }}/>

      
                
           
        );
    }
}



export default bloglist;


import React from "react"
import Layout from "../components/layout";
import SEO from "../components/seo"
import PageHeader from '../util/pageHeader'
import BlogList from '../components/blog/bloglist';
const Blog = () => (
  <Layout>
    <SEO title="Home" />
   
<PageHeader heading="Blog Post" source="https://livedemo00.template-help.com/wt_prod-2267/images/breadcrumbs-background-01.jpg"/>

 <BlogList/>  

  </Layout>
)




export default Blog
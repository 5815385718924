import React from 'react';
import styled from 'styled-components';
import Image from 'gatsby-image';
import { Link } from 'gatsby'
// import AniLink from "gatsby-plugin-transition-link/AniLink"


const blogcard = ({ post }) => {

    const { slug, title, image, publishDate } = post


    

    return (
        <BlogCardWrap className="col-md-4" key={title}>

<Link  to={`/blogs/${slug}`} >
        <div className="postbox">

<Image fluid={image.fluid} alt="single post" className="postimg" />
  
<div className="postcontent">
        <h3>{title}</h3>
        <p>{publishDate}</p>
        </div>
        </div> 
        </Link>    
        </BlogCardWrap>
    );
};


const BlogCardWrap = styled.div`




a{

    text-decoration:none !important;
   

}
.postbox{
    box-shadow:0px 1px 1px 1px #171717;
    background-color:#1f1f1fe0;
  
 .postimg{

width:100%;
height:200px;

 }
    h3{

color:var(--mainBlue);



    }
    p{

text-decoration:none;
color:grey;


    }

}

.postcontent{

    padding:30px 20px;
}
box-sizing:border-box;
padding:18px 20px;



`
export default blogcard;